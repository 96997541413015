.input {
    padding: .5rem;
    border-radius: 12px;
    border: 1px solid;
    border-color: var(--border, #9aca3c);
    outline: 0;
    width: min(500px, 70%);
    transition-duration: .2s;
}

.input:focus {
    border-width: 2px;
    width: min(700px, 80%);
    padding: 1rem;
}

.div {
    margin-top: auto;
    margin-bottom: 75px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.btn  {
    border: solid;
    min-width: 100px;
    font-weight: bold;
    background-color: var(--border, #9aca3c);
    border-width: 0;
    border-radius: 12px;
    outline: none;
    margin-top: 1rem;
    padding: 1rem 3rem;
    border-color: var(--bg-color, #00AEEF);
    transition-duration: .1s;
}

.btn:focus {
    border-width: 2px;
}