.menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(15px + 7rem);
    transform: translateY(-50%);
}

.m1 {
    left: 20px;
}

.m2 {
    right: 20px;
}

.btn {
    border-radius: 2px;
    border: 0;
    outline: 0;
    font-size: 1.5rem;
    padding: .5rem;
    color: white;
    background-color: var(--border);
    width: 3rem;
    height: 3rem;
    border-bottom: 1px solid lightgrey;
}

.btn:last-child {
    border-bottom: 0;
}

.btn:focus {
    outline: 0;
    border: 2px solid var(--bg-color);
}