.h1 {
    text-align: center;
    width: 100%;
    letter-spacing: 5px;
}

.div {
    box-shadow: 0 0 10px 0 var(--border);
    width: min(90%, 500px);
    margin: 3rem auto auto auto;
    max-height: 70vh;
    overflow-y: auto;
}

.item {
    width: 100%;
    height: 3rem;
    border-bottom: var(--border) 1px solid;
    display: flex;
}

.a {
    color: black;
    text-decoration: none;
}

.img {
    max-width: 2rem;
    max-height: 2rem;
    margin: .5rem 1rem;
}

.span {
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.numero {
    line-height: 1.2rem;
    font-size: 1rem;
}

.hidden {
    width: 2rem;
    opacity: 0;
}

.msg {
    position: relative;
    float: right;
    margin-right: 1rem;
    font-size: 1rem;
    color: var(--bg-color);
    top: 50%;
    transform: translateY(-50%);
}

.bold {
    color: var(--bg-color);
}