.disabled {
    color: #B4B4B4;
    background-color: #F2F2F2 !important;
}

.h4 {
    font-size: 1rem;
    padding-top: .5rem;
    margin: .5rem 0 .5rem -2rem;
    width: calc(100% + 2rem);
    overflow-wrap: break-word;
    hyphens: auto;
    overflow: hidden;
    display: -webkit-box;
    font-weight: bold;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.item {
    cursor: pointer;
    margin: 1rem 0 0 3rem;
    padding: .5rem 0;
    width: calc(100% - 3rem);
    flex-direction: row;
    display: flex;
    background-color: white;
    border-radius: 10px;
    min-height: 140px;
}

.item_container {
    width: calc(100% - 100px);
    margin: 0;
    min-height: 100%;
}

.asset > *{
    max-height: 200px;
    max-width: 200px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    object-fit: cover;

}

.asset {
    border-radius: 10px;
    width: 100px !important;
    height: 100px !important;
    max-width: 100px;
    max-height: 100px;
    position: relative;
    transform : translate(-2.5rem, 20px);
    overflow: hidden !important;
    object-fit: fill;
}

.shadow {
    box-shadow: lightgrey 0 0 10px;
}

.description {

    /* Il y avait un problème sur IOS, donc toutes les propriétés sont juste pour être sûr que la description ne dépasse pas */

    overflow: hidden;
    width: 100%;
    line-height: 1.2rem;
    min-height: 3.6rem;
    max-height: 3.6rem;
    height: 3.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    hyphens: auto;

}

.barre {
    color: grey;
}

.icons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    max-height: 1rem;
    transform: translateX(-2rem);
}

.icons > * {
    max-height: 1rem;
    max-width: 1rem;
    margin-left: .5rem;
}

.left {
    display: flex;
    flex-direction: column;
    height: calc(100px + 1rem);
}

.nbPoints {
    min-width: 2rem;
    text-align: center;
    line-height: .9rem;
    border: 1px solid var(--bg-color);
    border-radius: 5px;
}