.div {
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
    border-collapse: collapse;
}

.div > caption {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 5px;
    margin-bottom: 1rem;
}

.joker {
    padding: .5rem 1rem;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    border-bottom: 1px solid var(--border);
}

.div > tbody {
    box-shadow: 0 0 10px 0 var(--border);
}