.div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #e4f5ff;
    height: 100vh;
    padding-top: 2.5rem;
}

.qr {
    background-color: white;
    padding: .5rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
}

.params {
    border-radius: 10px;
    margin-top: 1.5rem;
    padding: .5rem 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img_qr {
    width: 164px;
    height: 164px;
}

.params > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.img {
    max-width: 40px;
    max-height: 40px;
}

.select {
    padding: .5rem 2rem;
    font-size: 1rem;
    text-transform: uppercase;
}

.select:focus {
    outline: var(--bg-color, #00AEEF) solid 2px;
}

.fullScreen {
    margin: .5rem 0 1rem 0;
}