.p {
    position: absolute;
    top: calc(15px + 1rem);
    right: 15px;
    padding: .3rem .8rem;
    border-radius: 12px;
    z-index: 3;
}

.red {
    background-color: #FF0800;

}

.blue {
    background-color: var(--bg-color);
}