.message {
    background-color: white;
    width: max-content;
    max-width: 70%;
    padding: .5rem;
    margin: 1rem;
    overflow-wrap: break-word;
    border-radius: 12px;
}

.right {
    align-self: flex-end;
    box-shadow: 0 0 1rem 0 var(--bg-color);
}

.left {
    align-self: flex-start;
    box-shadow: 0 0 1rem 0 var(--border);
}