.div {
    margin-top: auto;
    margin-bottom: 75px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn  {
    border: solid;
    font-weight: bold;
    min-width: 100px;
    background-color: var(--border, #9aca3c);
    border-width: 0;
    border-radius: 12px;
    outline: none;
    margin-top: 1rem;
    padding: 1rem 3rem;
    border-color: var(--bg-color, #00AEEF);
    transition-duration: .1s;
}

.btn:focus {
    border-width: 2px;
}