@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');


html, body {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif;
}

:root {
  --bg-color: #00aeef;
  --border: #9aca3c;
}

.swalIconElem {
  border-color: transparent !important;
}

button {
  cursor: pointer;
}

.button {
  padding: .5rem 1rem;
  border: 0;
  border-radius: 7px;
  background-color: var(--border, #9ACA3C);
  font-weight: bold;
}

@media screen and (min-width: 950px) {
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  *::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }
}