.div {
    width: 100vw;
    height: calc(100vh - 4rem);
    margin: 4rem 0 0 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titre {
    letter-spacing: .5rem;
    margin-bottom: 2rem;
}