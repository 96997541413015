.p {
    max-width: 90vw;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
    line-break: anywhere;
}

.h3 {
    text-align: center;
    margin-bottom: 2rem;
}