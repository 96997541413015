.bottom {
    border-top-left-radius: 7%;
    border-top-right-radius: 7%;
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-height: 80vh;
    background-color: #e4f5ff;
    border-top: 1px solid;
    border-color: var(--border, black);
    transition-duration: .5s;
}

.select {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    outline: 0;
    border: 2px var(--border) solid;
    background-color: white;
    border-radius: 5px;
    padding: .5rem .7rem;
    font-size: 1rem;
}

.select > option {
    font-size: 1rem;
}

.ul {
    width: 94%;
    height: 65vh;
    margin: 0 auto 2rem auto;
    overflow-y: auto;
    display: flex;
}

.li {
    display: inline-block;
    width: min(700px, 100%);
    margin-bottom: .2rem;
}

.grip {
    font-size: 1.5rem;
    top: 0;
    left: 50%;
    transform: translateX(50%);
    width: 50vw;
    text-align: center;
    border-bottom: 3px solid;
    border-color: var(--border, black);
}

.h3 {
    text-align: center;
    width: 100%;
}


