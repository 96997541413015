.item {
    margin-top: 1rem;
}

.item > * {
    padding: .2rem;
}

.item img {
    max-width: 40px;
    max-height: 40px;
}

.points {
    text-align: end;
}

.team {
    max-width: 40vw;
    text-overflow: ellipsis;
    overflow: hidden;
}