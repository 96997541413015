.full {
    margin-top: 20vh;
    height: 70vh;
    width: 100%;
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.caption {
    position: absolute;
    top: 6rem;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1rem;
    letter-spacing: 10px;
    font-weight: bold;

    font-size: 1.3rem;
}

.list {
    overflow-y: auto;
    min-width: min(90%, 500px);
    border-collapse: collapse;
    box-shadow: 0 0 10px 0 var(--border);
}

.list tr {
    border-bottom: var(--border) 1px solid;
}

.tbody tr:last-child {
    border-bottom: 0;
}

.icon {
    width: 50px;
}

.team {
    width: max-content;
    font-weight: bold;
}

.points {
    font-weight: bold;
    text-align: end;
}

.list td {
    padding: 1rem;
}