.div {
    height: 50vh;
    position: fixed;
    bottom: 0;
    width: calc(100vw - 2rem);
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bg-color);
    padding: 1rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid var(--border);
}

.p {
    width: calc(100vw - 2rem);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    height: calc(30vh - 9rem);
}

.h3 {
    text-align: center;
    margin-top: 1.5rem;
    width: calc(100vw - 2rem);
}

.close {
    font-size: 1rem;
    color: white;
    background-color: #F00;
    border-radius: 50%;
    padding: 5px 5px 3px 5px;
    width: 2rem;
    height: 2rem;
    border: 0 solid black;
    position: absolute;
    right: 10px;
    top: 5px;
}

.btn {
    margin-top: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: black;
}

.itineraire {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    background-color: var(--border);
    border: 0 var(--border) solid;
    outline: none;
    border-radius: 50%;
    color: white;
    padding: 5px;
}