.full {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color, #00AEEF);
}

.form {
    width: min(max(33%, 500px), 80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    padding: 1rem;
    border-color: var(--border, #9ACA3C);
    border-radius: 12px;
}

.form > * {
    margin: .5rem;
}

.input {
    text-align: center;
    padding: .5rem;
    border: solid 1px;
    border-color: var(--border, #9ACA3C);
    border-radius: 12px;
    width: 70%;
    transition-duration: .3s;
}

.input:focus {
    padding: .7rem;
    outline: none;
    border-width: 2px;
    width: 90%;
}

.help {
    text-align: center;
}

.button {
    padding: .5rem 1rem;
    border: 0;
    border-radius: 7px;
    background-color: var(--border, #9ACA3C);
    font-weight: bold;
}