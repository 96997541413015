.barreFermee {
    visibility: hidden;
    width: 3rem;
}

.barreFermee > * {
    visibility: hidden;
}

.barre {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 5;
    background-color: var(--bg-color, #ccc2);
    border: 1px solid;
    border-color: var(--border, black);
    padding: 5px;
    border-radius: 25px;
    transition-duration: .5s;
}

.barreOuverte {
    visibility: visible;
    width: min(calc(100vw - 42px), 500px);
    height: max-content;
}

.button {
    position: relative;
    color: white;
    font-size: 1.5rem;
    background-color: var(--border, black);
    border-radius: 50%;
    padding: 5px;
    width: 3rem;
    height: 3rem;
    border: 1px solid;
    border-color: var(--border, black);
    outline: 0;
}

.burgerButton {
    position: absolute;
    float: left;
}

.button:focus {
    border-width: 2px;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.msg {
    width: 12px;
    height: 12px;
    color: red;
    position: absolute;
    bottom: 0;
    right: 0;
}

@keyframes close-menu {
    from {
        width: min(calc(100vw - 30px), 500px);
        visibility: visible;
    }
    to {
        width: 0;
        visibility: hidden;
    }
}