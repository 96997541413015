.btnNav {
    position: absolute;
    background-color: #ccc7;
    font-size: 1rem;
    padding: .75rem;
    border: 0;
    transform: translateY(-50%);
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.asset > * {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.asset {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max(200px, 30%);
    overflow: hidden;
}

.bottom {
    width: min-content;
    max-width: 80%;
    height: min-content;
    margin: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.full {
    display: flex;
    flex-direction: column;
}

.bottom_btn {
    width: 15px;
    height: 5px;
    border-radius: 4px;
    border: 1px solid var(--border);
    outline: 0 solid var(--border);
    margin: 5px 3px 1rem 3px;
}

.bottom_btn:focus {
    outline-width: 2px;
}

.active {
    background-color: var(--bg-color);
}