.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(calc(100% - 10rem), max-content);
    height: max-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
    padding: 2.5rem 5rem 5rem 5rem;
    box-shadow: 0 0 0 100vw #aaaa;
    align-items: center;
}

.popup_btn {
    width: max-content;
    padding: .5rem;
    font-size: 1rem;
    background-color: var(--border);
    outline: none;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1px solid var(--bg-color)
}