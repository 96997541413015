.full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: min(100%, 1000px);
    height: 100%;
    background-color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.barre {
    border: 2px solid var(--border);
    width: min(calc(100% - 2rem), 1000px);
    margin: .5rem 1rem;
    border-radius: 1.5rem;
    height: 3rem;
    transition-duration: .2s;
}

.input {
    width: calc(100% - 4rem);
    font-size: 1rem;
    padding: .5rem 0;
    outline: 0;
    border: 0;
    margin-left: 1rem;
    background-color: transparent;
}

.btn {
    position: relative;
    font-size: 1rem;
    right: -3px;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: var(--bg-color);
    background-color: white;
    border: 3px solid var(--border);
    outline: none;
    margin: 0;
}
.btn:focus {
    border: 4px var(--bg-color) solid;
}

.barre:focus-within {
    border-color: var(--bg-color);
}

.messages {
    height: calc(100% - 9rem);
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.h1 {
    height: 3rem;
    padding: 0;
    margin: 1rem 0 0 5rem;
}

.back {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: white;
    border: 0;
    outline: 1px var(--bg-color);
    border-radius: 50%;
    font-size: 2.5rem;
    color: var(--border);
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9;
}