.full {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close {
    z-index: 11;
    font-size: 1rem;
    color: white;
    background-color: #F007;
    border-radius: 50%;
    /* pour que la croix soit centrée verticalement */
    padding: 5px 5px 3px 5px;
    width: 2rem;
    height: 2rem;
    border: 0 solid black;
    position: absolute;
    right: 10px;
    top: 10px;
}