.back {
    z-index: 11;
    font-size: 2rem;
    background-color: transparent;
    padding: 0;
    border: 0 solid black;
    color: #bbb;
    position: absolute;
    left: 10px;
    top: 10px;
}

.title {
    border-bottom: 2px solid;
    border-color: var(--border, #9ACA3C);
    width: max-content;
    max-width: 75vw !important;
    text-align: center;
    padding: 0 2rem .5rem 1.5rem;
    overflow-wrap: break-word;
    hyphens: auto;
    display: flex;
    align-items: center;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.presentation {
    max-width: 80%;
    padding: 5%;
    text-align: justify;
    height: max-content;
    overflow-y: auto;
}

.joker {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background-color: var(--border);
    color: var(--bg-color);
    outline: 0;
    -moz-outline-radius: 50%;
    border: 0;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
}

.joker:focus {
    border: 3px solid var(--bg-color);
}

.icon {
    margin-right: 5px;
    max-width: 1rem;
    max-height: 1rem;
}