.title {
    border-bottom: 2px solid;
    border-color: var(--border, #9ACA3C);
    width: max-content;
    max-width: 75vw !important;
    text-align: center;
    padding: 0 2rem .5rem 1.5rem;
    overflow-wrap: break-word;
    hyphens: auto;
    display: flex;
    align-items: center;
}

.info {
    color: #666666;
    padding: 0;
    margin-top: 0;
}

.presentation {
    max-width: 70%;
    padding: 5%;
    text-align: justify;
    overflow-y: auto;
}

.button {
    padding: 1rem 3rem;
    border: 0;
    border-radius: 7px;
    background-color: var(--border, #9ACA3C);
    font-weight: bold;
    margin-top: auto;
    margin-bottom: 1rem;
}

.full {
    height: 100%;
}

.err {
    color: red;
    padding: 0;
    margin-top: 0;
}

.icon {
    margin-right: 5px;
    max-width: 1rem;
    max-height: 1rem;
}